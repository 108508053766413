import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Form,
  Input,
  InputNumber,
  Button,
  Checkbox,
  Divider,
  Switch,
  Row,
  Col,
  Select,
  Upload,
  Image,
  message,
  Space,
  Tabs, Badge
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import cx from "classnames";
import produce from "immer";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Header from "../../../components/HeaderModal";
import {ReactComponent as Trash} from '../../../assets/icons/trash.svg';
import { updateProduct, createProduct, getProductList } from "../../../redux/slices/products";
import { normFile, beforeUpload, dummyRequest } from "../../../utils/images";
import { validateFloatKeyPress } from "../../../utils/format";
import { SearchProductList } from "../../../components/SearchProductList";
import styles from "../styles/Editor.module.css";

const { Option } = Select;

const Editor = ({
  close,
  product,
  categories,
  brands,
  handleProduct,
  deleteProdImage,
  reloadProds
}) => {
  const [deleting, handleDeleting] = useState(false);
  const [sending, handleSending] = useState(false);
  const [image, handleImage] = useState("");
  const [imageSmall, handleImageSmall] = useState("");
  const [imageBase, handleImageBase] = useState("");
  const [imageBanner, handleImageBanner] = useState("");
  const [content, handleContent] = useState('');
  const [reqopt, setReqopt] = useState(false);
  const [deletingImage, setDeletingImage] = useState(false);
  const [deletingImage2, setDeletingImage2] = useState(false);
  const [value, setValue] = useState([]);
  const [showEscalada, setShowEscalada] = useState("none");

  
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    handleImage("");
    handleImageSmall("");
    handleImageBase("");
    handleImageBanner("");
    form.resetFields();
    if (product) {
      const custom = ((typeof product.custom_data==='function')||(typeof product.custom_data==='object'))?product.custom_data:JSON.parse(product.custom_data);
      const stock = ((typeof product.custom_stock==='function')||(typeof product.custom_stock==='object'))?product.custom_stock:JSON.parse(product.custom_stock);
      const cconfig = ((typeof product.custom_config==='function')||(typeof product.custom_config==='object'))?product.custom_config: JSON.parse(product.custom_config);
      let data = {
        category: categories.filter(x=>x.id==product.categoryId)[0].id,
        brand: brands.filter(x=>x.id==product.brandId)[0].id,
        name: product.name,
        active: product.active,
        stands: product.homefeature,
        promo: product.homepromo,
        position: product.position,
        small_description: product.small_desc,
        description: product.desc,
        size: custom.medida,
        power: custom.potencia,
        realpower: custom.potenciaReal,
        impedance: custom.impedancia,
        coil: custom.bobina,
        price: product.price,
        price2: product.price2,
        sku: stock.sku,
        stock_state: stock.estado,
        stock_qty: product.existencia,
        saletype: stock.estiloVenta,
        limit_stock: stock.umbralexistencia,
        prodlist: cconfig.dirigidas,
        prodcross: cconfig.cruzadas
      };
      if(stock.tipoVenta) {
        data.stock_tipoVenta = stock.tipoVenta;
        data.stock_escalada = stock.escalas;
        data.stock_useEscala = stock.useEscala;
        setShowEscalada("block");
      }
      form.setFieldsValue(data);
      handleImage(product.image || "");
      handleImageSmall(product.small_image || "");
      handleImageBase(product.base_image || "");
      handleImageBanner(product.banner_image || "");
    } else {
      const data = {
        small_description: "",
        description: "<p>Descripcion del Producto</p>",
        sku_remoto: "",
        stock_state: 1,
        limit_stock: 2,
        position: 0,
        active: true
      };
      form.setFieldsValue(data);
    }
  }, [product]);

  const CheckMark = ({ label, value }) => (
    <Col span={12}>
      <Checkbox
        className={styles.check}
        value={value}
        style={{ lineHeight: "32px" }}
      >
        {label}
      </Checkbox>
    </Col>
  );

  const changeEscalada = (e) => {
    if(e.target.checked) {
      //form.setFieldsValue({...data, });
      setShowEscalada("block");
      setReqopt(true);
    } else {
      setReqopt(false);
      setShowEscalada("none");
    }
  }

  const deleteImageBase = async () => {
    setDeletingImage(true);
    await deleteProdImage(product.id, "base");
    handleImageBase("");
    setDeletingImage(false);
  }

  const deleteImageBanner = async () => {
    setDeletingImage2(true);
    await deleteProdImage(product.id, "banner");
    handleImageBanner("");
    setDeletingImage2(false);
  }

  const save = async () => {
    try {
      handleSending(true);
      const values = await form.validateFields();
      if (imageSmall && imageSmall.length) {
        const info = new FormData();
        if (product) {
          info.append("id", product.id);
        }
        
        const custom = {
          medida: values.size,
          potencia: values.power,
          potenciaReal: values.realpower,
          impedancia: values.impedance,
          bobina: values.coil
        };
        let stock = {
          sku: values.sku,
          estado: values.stock_state,
          cantidad: values.stock_qty,
          estiloVenta: values.saletype,
          umbralexistencia: values.limit_stock
        };
        if(values.stock_useEscala){
          stock.useEscala = values.stock_useEscala;
          stock.tipoVenta = values.stock_tipoVenta;
          stock.escalas = values.stock_escalada;
        }

        const cconfig = {
          dirigidas: values.prodlist,
          cruzadas: values.prodcross
        };
        
        info.append("name", values.name);
        info.append("price", values.price);
        info.append("price2", values.price2);
        info.append("category", values.category);
        info.append("active", values.active);
        info.append("feature", values.stands);
        info.append("promo", values.promo);
        info.append("delivery", "true");
        info.append('brand', values.brand);
        info.append('position', values.position);
        info.append("mini_description", values.small_description || "");
        info.append("description", values.description);
        info.append("custom", JSON.stringify(custom));
        info.append("stock", JSON.stringify(stock));
        info.append("config", JSON.stringify(cconfig));
        info.append(
          "image",
          values.image?.length ? values.image[0]?.originFileObj : image
        );
        info.append(
          "small_image",
          values.imageSmall?.length ? values.imageSmall[0]?.originFileObj : imageSmall
        );

        info.append(
          "base_image",
          values.imageBase?.length ? values.imageBase[0]?.originFileObj : imageBase
        );

        info.append(
          "banner_image",
          values.imageBanner?.length ? values.imageBanner[0]?.originFileObj : imageBanner
        );

        console.log(info);
        if (product) {
          const response = await dispatch(
            updateProduct(product.id, info, values.category, product.categoryId)
          );
          if (response.status === "success") {
            message.success("Cambios realizados con éxito");
            handleProduct(response.product);
          } else {
            message.error(response.status + ", ¡Hubo un problema! Inténtalo de nuevo");
          }
        } else {
          const response = await dispatch(createProduct(info, values.category));
          if (response.status === "success") {
            message.success("Cambios realizados con éxito");
            handleProduct(response.product);
          } else {
            message.error(response.status + " - no se pudo procesar, Inténtalo de nuevo");
          }
        }
      } else {
        message.error("Incluye las imagenes requeridas");
      }
      handleSending(false);
      reloadProds();
      close();
    } catch (e) {
      console.log(e);
      close();
    }
  };

  async function fetchProductList(search) {
    console.log('fetching products', search);
    const prods = await getProductList(search, product.categoryId, "with");
    // console.log(prods);
    return prods.products;
    /*
    return fetch('https://randomuser.me/api/?results=5')
      .then((response) => response.json())
      .then((body) =>
        body.results.map((user) => ({
          label: `${user.name.first} ${user.name.last}`,
          value: user.login.username,
        })),
      );
      */
  }

  return (
    <div className={cx(styles.editor, "editor-product")}>
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        name="product-editor"
        autoComplete="off"
        colon={false}
        requiredMark={false}
        form={form}
        onFinish={save}
      >
        <div className={cx(styles.mainInfo, styles.section)}>
          <Header
            title={product!=null ? "Editar Producto" : "Crear Producto"}
            className={styles.section}
            actions={
              <Form.Item className={cx(styles.itemSubmit, styles.itemColumn)}>
                  <Button
                    loading={sending}
                    className={styles.submit}
                    type="primary"
                    size="large"
                    htmlType="submit"
                  >
                    GUARDAR
                  </Button>
                </Form.Item>
            }
          />
          <Row>
          
            <Col span={24}>
            <Badge.Ribbon text={product?.sku_remoto?"VINCULADO":""}>
            <Tabs tabPosition="left">
              <Tabs.TabPane tab={<div className={cx(styles.label, styles.tabLabel)}>DATOS GENERALES</div>} key="item-1" forceRender>
                <Row justify="start" gutter={40}>
                  <Col span={7}>
                    <Form.Item name="active" label={<span className={styles.blue}>Publicado</span> } valuePropName="checked">
                      <Switch />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={9}>
                    <Form.Item name="stands" label={<span className={styles.blue}>Destacado</span> } valuePropName="checked">
                      <Switch />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={8}>
                    <Form.Item name="promo" label={<span className={styles.blue}>Promocion</span> } valuePropName="checked">
                      <Switch />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={40}>
                  <Col span={20}>
                    <Form.Item
                      label={<span className={styles.label}>CATEGORÍA</span>}
                      rules={[{ required: true, message: "Ingresar categoría" }]}
                      name="category"
                    >
                      <Select size="large" className={styles.input}>
                        {categories.map((category) => {
                          if(!(category.type == 1 || category.type == 6)) {
                            return;
                          }
                          return (
                          <Option value={category.id}>
                            {category.name}
                          </Option>
                          )
                        })}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label={<span className={styles.label}>MARCA</span>}
                      rules={[{ required: true, message: "Ingresar marca" }]}
                      name="brand"
                    >
                      <Select size="large" className={styles.input}>
                        {brands.map((brand) => {
                          return (
                          <Option value={brand.id}>
                            {brand.name}
                          </Option>
                          )
                        })}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label={<span className={styles.label}>NOMBRE</span>}
                      name="name"
                      rules={[{ required: true, message: "Ingresar nombre" }]}
                    >
                      <Input size="large" className={styles.input} />
                    </Form.Item>
                    <Form.Item
                      label={<span className={styles.label}>DESCRIPCIÓN CORTA</span>}
                      name="small_description"
                      required
                    >
                      <Input size="large" className={styles.input} />
                    </Form.Item>
                    <Form.Item
                      label={
                        <span className={styles.label}>DESCRIPCIÓN PRINCIPAL</span>
                      }
                      name="description"
                      required                      
                      rules={[
                        {
                          required: true,
                          message: "Ingresar descripción en detalle",
                        },
                      ]}
                    >
                      <ReactQuill theme="snow" value={content ?? ""} onChange={handleContent}/>
                    </Form.Item>
                    <Form.Item name="position" label={<span className={styles.blue}>Posicion (Orden)</span> }>
                      <InputNumber placeholder="Numero de posicion" />
                    </Form.Item>
                  </Col>
                </Row>
              </Tabs.TabPane>
              <Tabs.TabPane tab={<div className={cx(styles.label, styles.tabLabel)}>ATRIBUTOS</div>} key="item-2" forceRender>
              <Row gutter={40}>
                  <Col span={18}>
                        <Form.Item
                          label={<span className={styles.label}>Medida</span>}
                          name="size"
                          rules={[
                            {
                              required: true,
                              message: "Ingresar la medida del producto",
                            },
                          ]}
                        >
                          <Input className={styles.input} />
                        </Form.Item>
                        <Form.Item
                          label={<span className={styles.label}>Potencia</span>}
                          name="power"
                          rules={[
                            {
                              required: true,
                              message: "Ingresar potencia",
                            },
                          ]}
                        >
                          <Input className={styles.input} />
                        </Form.Item>
                        <Form.Item
                          label={<span className={styles.label}>Potencia real</span>}
                          name="realpower"
                        >
                          <Input className={styles.input} />
                        </Form.Item>
                        <Form.Item
                          label={<span className={styles.label}>Impedancia</span>}
                          name="impedance"
                        >
                          <Input className={styles.input} />
                        </Form.Item>
                        <Form.Item
                          label={<span className={styles.label}>Bobina</span>}
                          name="coil"
                        >
                          <Input className={styles.input} />
                        </Form.Item>
                  </Col>
                </Row>
              </Tabs.TabPane>
              <Tabs.TabPane tab={<span className={cx(styles.label, styles.tabLabel)}>PRECIO</span>} key="item-3" forceRender>
                <Row gutter={40}>
                  <Col span={18}>
                    <Form.Item
                      label={<span className={styles.label}>PRECIO NORMAL</span>}
                      name="price"
                      normalize={validateFloatKeyPress}
                      rules={[{ required: true, message: "Ingresar precio" }]}
                    >
                      <Input size="large" prefix="$" className={styles.input} />
                    </Form.Item>

                    <Form.Item
                      label={<span className={styles.label}>PRECIO DISTRIBUIDOR</span>}
                      name="price2"
                      normalize={validateFloatKeyPress}
                      rules={[{ required: true,  message: "Ingresar precio" }]}
                    >
                      <Input size="large" prefix="$" className={styles.input} />
                    </Form.Item>
                    <Divider orientation="left">
                      <Form.Item name="stock_useEscala" valuePropName="checked" >
                          <Checkbox onChange={changeEscalada}>Usar precio escalado</Checkbox>
                        </Form.Item>
                    </Divider>
                    <Col span={24} style={{ display: showEscalada }}>
                      <Form.Item
                              label={<span className={styles.label}>Tipo de Escalada</span>}
                              name="stock_tipoVenta"
                              rules={[
                                {
                                  required: reqopt,
                                  message: "Ingresar tipo",
                                },
                              ]}
                            >
                              <Select className={styles.input} options={[ { value: 'Unit', label: 'Rango de unidades' }, { value: 'box', label: 'Cajas' } ]} />
                      </Form.Item>
                      <Form.List name="stock_escalada">
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(({ key, name, ...restField }) => (
                              <Space
                                key={key}
                                style={{
                                  display: 'flex',
                                  marginBottom: 8,
                                }}
                                align="baseline"
                              >
                                <Form.Item
                                  {...restField}
                                  name={[name, 'id']}
                                  initialValue={(key+1)}
                                >
                                  <span>{(key+1)}</span>
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'min']}
                                  label={<span className={styles.label}>Minimo</span>}
                                  labelCol={true}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'falta minimo',
                                    },
                                  ]}
                                >
                                  <InputNumber placeholder="limite inferior" />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'max']}
                                  label={<span className={styles.label}>Maximo</span>}
                                  labelCol={true}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'falta maximo',
                                    },
                                  ]}
                                >
                                  <InputNumber defaultValue={3} placeholder="limite superior" />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'desc']}
                                  label={<span className={styles.label}>Nombre</span>}
                                  labelCol={true}
                                  labelAlign="left"
                                  style={{
                                    display: "flex",
                                    flexDirection: "column"
                                  }}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'falta desc',
                                    },
                                  ]}
                                >
                                  <Input placeholder="Descripcion del precio" className={styles.input} />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'price']}
                                  label={<span className={styles.label}>Precio</span>}
                                  labelCol={true}
                                  labelAlign="left"
                                  style={{
                                    display: "flex",
                                    flexDirection: "column"
                                  }}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'falta precio',
                                    },
                                  ]}
                                >
                                  <Input placeholder="Precio"  prefix="$"  />
                                </Form.Item>
                                <MinusCircleOutlined onClick={() => remove(name)} />
                              </Space>
                            ))}
                            <Form.Item>
                              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                Agregar precio
                              </Button>
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                    </Col>
                  </Col>
                </Row>
              </Tabs.TabPane>
              <Tabs.TabPane tab={<span className={cx(styles.label, styles.tabLabel)}>INVENTARIO</span>} key="item-4" forceRender>
                <Row>
                  <Col span={18}>
                        <Form.Item
                          label={<span className={styles.label}>SKU</span>}
                          name="sku"
                          rules={[
                            {
                              required: true,
                              message: "Ingresar sku",
                            },
                          ]}
                        >
                          <Input className={styles.input} />
                        </Form.Item>
                        <Form.Item
                          label={<span className={styles.label}>Estado de Inventario</span>}
                          name="stock_state"
                          rules={[
                            {
                              required: true,
                              message: "Ingresar estado",
                            },
                          ]}
                        >
                          <Select className={styles.input} options={[ { value: '1', label: 'En Existencia' }, { value: '0', label: 'Agotado' } ]} />
                        </Form.Item>
                        <Form.Item
                          label={<span className={styles.label}>Cantidad de inventario</span>}
                          name="stock_qty"
                        >
                          <Input className={styles.input} />
                        </Form.Item>
                        <Form.Item
                          label={<span className={styles.label}>Forma de venta</span>}
                          name="saletype"
                          rules={[
                            { required: true, message: "Ingresar forma de venta" },
                          ]}
                        >
                          <Input placeholder="Cada Uno" className={styles.input} />
                        </Form.Item>
                        <Form.Item
                          label={<span className={styles.label}>Umbral de poca existencia</span>}
                          name="limit_stock"
                        >
                          <Input defaultValue={4} className={styles.input} />
                        </Form.Item>
                  </Col>
                </Row>
              </Tabs.TabPane>
              <Tabs.TabPane tab={<span className={cx(styles.label, styles.tabLabel)}>IMAGENES</span>} key="item-5" forceRender>
                <Row>
                  <Col span={18}>
                    <div className={styles.photoWrap}>
                      <Row gutter={20}>
                        <Col span={8}>
                          {image.length ? (
                            <>
                            <Image
                              width="100%"
                              src={image}
                              className={styles.photo}
                            />
                            </>
                          ) : (
                            <div className={styles.photoEmpty} />
                          )}
                        </Col>
                        <Col span={14}>
                          <Form.Item
                            name="image"
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                            label={
                              <span className={styles.label}>IMAGEN PRINCIPAL</span>
                            }
                            labelCol={12}
                            className={styles.itemColumn}
                            extra="(Formatos jpg o png de 230x295px)"
                          >
                            <Upload
                              maxCount={1}
                              showUploadList={false}
                              customRequest={(e) => dummyRequest(e, handleImage)}
                              beforeUpload={beforeUpload}
                              listType="picture"
                            >
                              <Button className={styles.uploadImage}>
                                Cambiar imagen
                              </Button>
                            </Upload>
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                    <div className={styles.photoWrap}>
                      <Row gutter={20}>
                        <Col span={8}>
                          {imageSmall.length ? (
                            <Image
                              width="100%"
                              src={imageSmall}
                              className={styles.photo}
                            />
                          ) : (
                            <div className={styles.photoEmpty} />
                          )}
                        </Col>
                        <Col span={14}>
                          <Form.Item
                            name="imageSmall"
                            valuePropName="fileList"
                            label={
                              <span className={styles.label}>IMAGEN SECUNDARIA</span>
                            }
                            labelCol={12}
                            className={styles.itemColumn}
                            extra="(Formatos jpg o png de 500x400px)"
                            getValueFromEvent={normFile}
                          >
                            <Upload
                              maxCount={1}
                              showUploadList={false}
                              customRequest={(e) => dummyRequest(e, handleImageSmall)}
                              beforeUpload={beforeUpload}
                              listType="picture"
                            >
                              <Button className={styles.uploadImage}>
                                Cambiar imagen
                              </Button>
                            </Upload>
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                    <div className={styles.photoWrap}>
                      <Row gutter={20}>
                        <Col span={8}>
                          {imageBase.length ? (
                            <>
                            <Image loading={deletingImage}
                              width="100%"
                              src={imageBase}
                              className={styles.photo}
                            />
                              <Trash title="Eliminar Imagen" onClick={deleteImageBase} />
                            </>
                          ) : (
                            <div className={styles.photoEmpty} />
                          )}
                        </Col>
                        <Col span={14}>
                          <Form.Item
                            name="imageBase"
                            valuePropName="fileList"
                            label={<span className={styles.label}>IMAGEN EXTRA 1</span>}
                            labelCol={12}
                            className={styles.itemColumn}
                            extra="(Formatos jpg o png de 500x400px)"
                            getValueFromEvent={normFile}
                          >
                            <Upload
                              maxCount={1}
                              showUploadList={false}
                              customRequest={(e) => dummyRequest(e, handleImageBase)}
                              beforeUpload={beforeUpload}
                              listType="picture"
                            >
                              <Button className={styles.uploadImage}>
                                Cambiar imagen
                              </Button>
                            </Upload>
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                    <div className={styles.photoWrap}>
                      <Row gutter={20}>
                        <Col span={8}>
                          {imageBanner.length ? (
                            <>
                            <Image  loading={deletingImage2}
                              width="100%"
                              src={imageBanner}
                              className={styles.photo}
                            />
                            <Trash title="Eliminar Imagen" onClick={deleteImageBanner} />
                            </>
                          ) : (
                            <div className={styles.photoEmpty} />
                          )}
                        </Col>
                        <Col span={14}>
                          <Form.Item
                            name="imageBanner"
                            valuePropName="fileList"
                            label={<span className={styles.label}>IMAGEN EXTRA 2</span>}
                            labelCol={12}
                            className={styles.itemColumn}
                            extra="(Formatos jpg o png de 500x400px)"
                            getValueFromEvent={normFile}
                          >
                            <Upload
                              maxCount={1}
                              showUploadList={false}
                              customRequest={(e) => dummyRequest(e, handleImageBanner)}
                              beforeUpload={beforeUpload}
                              listType="picture"
                            >
                              <Button className={styles.uploadImage}>
                                Cambiar imagen
                              </Button>
                            </Upload>
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Tabs.TabPane>
              <Tabs.TabPane tab={<span className={cx(styles.label, styles.tabLabel)}>PRODUCTOS RELACIONADOS</span>} key="item-6" forceRender>
              <Row>
                  <Col span={24}>
                    <Form.Item
                      label={<span className={styles.label}>Selecciona productos<br/>para mostrar en detalle</span>}
                      labelCol={5}
                      name="prodlist"
                    >
                        <SearchProductList
                          mode="multiple"
                          value={value}
                          placeholder="Buscar productos"
                          fetchOptions={fetchProductList}
                          onChange={(newValue) => {
                            setValue(newValue);
                          }}
                          style={{
                            width: '100%',
                          }}
                        />
                    </Form.Item>
                    {/* 
                    <Form.Item
                      label={<span className={styles.label}>Ventas Cruzadas</span>}
                      name="prodcross"
                    >
                        <SearchProductList
                          mode="multiple"
                          value={value}
                          placeholder="Buscar productos"
                          fetchOptions={fetchProductList}
                          onChange={(newValue) => {
                            setValue(newValue);
                          }}
                          style={{
                            width: '100%',
                          }}
                        />
                    </Form.Item>
                    */}
                  </Col>
                </Row>
              </Tabs.TabPane>
            </Tabs>
            </Badge.Ribbon>
</Col>
</Row>
        </div>
    
        
      
      </Form>
    </div>
  );
};

export default Editor;

